<template>
  <el-drawer
    :visible="show"
    :size="600"
    direction="rtl"
    :title="title"
    @close="close"
    :wrapperClosable="false"
  >
    <div
      class="py-5 h100 dp-flex flex-d-c copy"
      v-loading="loading"
    >
      <div class="flex-1 h-0 overflow-scroll pr-4">
        <el-form
          :model="formData"
          label-width="120px"
          :rules="rules"
          ref="formRef"
        >
          <el-form-item
            label="复制数量"
            prop="copyNum"
          >
            <el-input-number
              v-model="formData.copyNum"
              :precision="0"
              :min="1"
              :max="10"
              controls-position="right"
              style="width: 100%; text-align: right"
            >
            </el-input-number>
          </el-form-item>
          <el-form-item
            :label="level == 'campaign' ? '系列名称' : '广告名称'"
            prop="copyNum"
            v-if="level != 'adset'"
            :maxlength="maxLengthName"
          >
            <el-input
              v-model="formData.name"
              :disabled="formData.copyNum > 1 || level === 'ad'"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="克隆商品"
            prop="cloneSale"
            v-if="checkData.hasCloneSale"
          >
            <el-radio-group v-model="formData.cloneSale">
              <el-radio :label="false">否(默认商品)</el-radio>
              <el-radio :label="true">是(商城3.0)</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item
            label="复制至"
            prop="toAccountId"
            v-if="checkData.hasToAccountId"
          >
            <copytoAccount
              v-model="formData.toAccountId"
              :platform="data.ad.platform"
            ></copytoAccount>
          </el-form-item>

          <el-form-item
            label="像素"
            prop="toPixelId"
            v-if="checkData.hasToPixelId"
          >
            <copyPixel
              v-model="formData.toPixelId"
              :accountId="formData.toAccountId"
              :platform="data.ad.platform"
            ></copyPixel>
          </el-form-item>
          <el-form-item
            label="关联商品"
            prop="sale_relation_type"
            v-if="checkData.hasSaleRelationType"
          >
            <saleRelationType
              v-model="formData.sale_relation_type"
              @change="onChangeSaleRelationType"
            ></saleRelationType>
          </el-form-item>
          <el-form-item
            prop="promotionUrl"
            label="推广连接"
            v-if="checkData.hasPromotionUrl"
          >
            <el-input
              v-model="formData.promotionUrl"
              @keyup.enter.native="onChangePromotionUrl"
              @blur="onChangePromotionUrl"
              v-loading="promotionUrlLoading"
            ></el-input>
          </el-form-item>

          <el-form-item
            prop="toSaleId"
            label="商品id"
            v-if="checkData.hasSaleId"
          >
            <sale :saleInfo="saleInfo"></sale>
          </el-form-item>

          <el-form-item
            prop="toSiteId"
            label="站点"
            v-if="checkData.hasSiteId"
          >
            <site
              v-model="siteInfo"
              :disabled="formData.sale_relation_type != 'clone_sale'"
            ></site>
          </el-form-item>
          <el-form-item
            prop="toSiteId"
            label="地区"
            v-if="checkData.hasPromotionUrl"
          >
            <el-input
              disabled
              :value="siteInfo ? siteInfo.countryName : ''"
            ></el-input>
          </el-form-item>
          <el-form-item
            prop="campaignActive"
            label="复制后状态"
            v-if="level == 'campaign'"
          >
            <el-radio-group v-model="formData.campaignActive">
              <el-radio :label="true">开启</el-radio>
              <el-radio :label="false">暂停</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            prop="conversionWindow"
            label="转化时间窗"
            v-if="checkData.hasConversionWindow"
          >
            <el-radio-group v-model="formData.conversionWindow">
              <el-radio label="">默认</el-radio>
              <el-radio label="SWIPE_28DAY_VIEW_1DAY">28+1</el-radio>
              <el-radio label="SWIPE_7DAY">7+0</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="复制广告组"
            prop="copeChildType"
            v-if="level == 'campaign' && !data.ad?.smartPerformanceCampaign"
          >
            <el-radio-group v-model="formData.copeChildType">
              <el-radio label="total">全部广告组</el-radio>
              <el-radio
                label="putIn"
                v-show="copyAdCheckData.isCanCopyActive"
                >投放中的广告组</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="开始时间"
            prop="mStartTime"
            v-if="checkData.hasAdsetStartTime"
          >
            <el-date-picker
              v-model="formData.mStartTime"
              type="datetime"
              placeholder="选择日期时间"
              style="width: 100%"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            v-if="checkData.hasAdsetEndTime"
            label="结束时间"
            prop="mEndTime"
          >
            <el-date-picker
              v-model="formData.mEndTime"
              type="datetime"
              placeholder="选择日期时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              style="width: 100%"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            label="系列日预算"
            v-if="checkData.hasCampaignDailyBudget"
          >
            <div class="dp-flex">
              <el-form-item
                prop="campaignDailyBudget"
                class="flex-1"
              >
                <el-input
                  placeholder="请填写系列日预算,不填写,使用原有系列日预算"
                  v-model="formData.campaignDailyBudget"
                  v-to-fixed="budgetCheck.budgetScale"
                  clearable
                >
                  <template slot="append"> {{ data.statistics.currency }}</template>
                </el-input>
              </el-form-item>
            </div>
          </el-form-item>
          <el-form-item
            label="系列总预算"
            v-if="checkData.hasCampaignLifetimeBudget"
          >
            <div class="dp-flex">
              <el-form-item
                prop="campaignLifetimeBudget"
                class="flex-1"
              >
                <el-input
                  placeholder="请填写系列总预算,不填写,使用原有系列总预算"
                  v-model="formData.campaignLifetimeBudget"
                  v-to-fixed="budgetCheck.budgetScale"
                  clearable
                >
                  <template slot="append"> {{ data.statistics.currency }}</template>
                </el-input>
              </el-form-item>
            </div>
          </el-form-item>
          <el-form-item
            label="组日预算"
            v-if="checkData.hasAdsetDailyBudget"
          >
            <div class="dp-flex">
              <el-form-item
                prop="adsetDailyBudget"
                class="flex-1"
              >
                <el-input
                  placeholder="请填写组日预算,不填写,使用原有组日预算"
                  v-model="formData.adsetDailyBudget"
                  v-to-fixed="budgetCheck.budgetScale"
                  clearable
                >
                  <template slot="append"> {{ data.statistics.currency }}</template>
                </el-input>
              </el-form-item>
            </div>
          </el-form-item>
          <el-form-item
            label="组总预算"
            v-if="checkData.hasAdsetLifetimeBudget"
          >
            <div class="dp-flex">
              <el-form-item
                prop="adsetLifetimeBudget"
                class="flex-1"
              >
                <el-input
                  placeholder="请填写组总预算,不填写,使用原有组总预算"
                  v-model="formData.adsetLifetimeBudget"
                  v-to-fixed="budgetCheck.budgetScale"
                  clearable
                >
                  <template slot="append"> {{ data.statistics.currency }}</template>
                </el-input>
              </el-form-item>
            </div>
          </el-form-item>
          <el-form-item
            :label="data.ad.bidModelName"
            prop="campaignBidAmount"
            v-if="checkData.hasCampaignBidAmount"
            class="flex-1"
          >
            <el-input
              placeholder="请填写出价,不填写,使用原有出价"
              v-model="formData.campaignBidAmount"
              v-to-fixed="budgetCheck.bidScale"
              clearable
            >
              <template
                slot="append"
                v-if="checkData.bidHasCurrency"
              >
                {{ data.statistics.currency }}</template
              >
            </el-input>
          </el-form-item>

          <el-form-item
            label="状态"
            v-if="level == 'adset'"
            prop="adsetActive"
          >
            <el-switch
              v-model="formData.adsetActive"
              active-text="开启"
            ></el-switch>
          </el-form-item>
          <el-form-item
            label="优化目标"
            prop="optimizationGoal"
            v-if="checkData.hasOptimization"
          >
            <el-radio-group
              v-model="formData.optimizationGoal"
              @change="changeOptimizationGoal"
            >
              <el-radio
                v-for="optimization in optimizationList"
                :key="optimization.value"
                :label="optimization.value"
                >{{ optimization.name }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="竞价类型"
            prop="bidType"
            v-if="checkData.hasBidModel && bidModelList.length"
          >
            <el-radio-group v-model="formData.bidType">
              <el-radio
                v-for="bidModel in bidModelList"
                :key="bidModel.value"
                :label="bidModel.value"
                >{{ bidModel.name }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item
            :label="data.ad.bidModelName"
            prop="adsetBidAmount"
            v-if="checkData.hasAdsetBidAmount"
          >
            <div class="dp-flex">
              <el-input
                :placeholder="requiredAdsetBidAmount ? '请填写出价' : '请填写出价,不填写,使用原有出价'"
                v-model="formData.adsetBidAmount"
                v-to-fixed="budgetCheck.bidScale"
                clearable
              >
                <template
                  slot="append"
                  v-if="checkData.bidHasCurrency"
                >
                  {{ data.statistics.currency }}</template
                >
              </el-input>
            </div>
          </el-form-item>
          <el-form-item
            label="状态"
            v-if="level == 'ad'"
            prop="adActive"
          >
            <el-switch
              v-model="formData.adActive"
              active-text="开启"
            ></el-switch>
          </el-form-item>
        </el-form>
      </div>
      <div class="dp-flex justify-end pr-4">
        <el-button
          type="info"
          @click="close"
          >取消</el-button
        >
        <el-button
          type="primary"
          @click="sure"
          >确定</el-button
        >
        <el-button
          v-if="data.ad.platform === 'snapchat'"
          type="primary"
          @click="handleNextEdit"
          >下一步，编辑</el-button
        >
      </div>
    </div>
    <next-edit
      v-if="nextEditDialogShow"
      :level="level"
      :nextEditDialogShow="nextEditDialogShow"
      :treeParams="treeParams"
      @updateNextEditDialogShow="updateNextEditDialogShow"
      @multipleCopySuccess="multipleCopySuccess"
    />
  </el-drawer>
</template>
<script>
import moment from 'moment';
import { copyAdCheck, queryConfigDataCheck, copyAdAsync, promotionInfo } from '@/api/multiPlatform';
import { optimizationGoalTypes } from './copy.js';
import copytoAccount from './copy/copytoAccount.vue';
import copyPixel from './copy/pixel.vue';
import saleRelationType from './copy/saleRelationType.vue';
import sale from './copy/sale.vue';
import site from './copy/site.vue';
import NextEdit from './nextEdit/index.vue';
const platformMaxLen = {
  facebook: 512,
  tiktok: 512,
  google: 255,
  snapchat: 500,
  line: 500,
  twitter: 500,
};

export default {
  props: {
    show: Boolean,
    level: String,
    data: {
      default: () => ({ ad: {} }),
    },
  },
  components: { copytoAccount, copyPixel, saleRelationType, sale, site, NextEdit },
  data() {
    return {
      loading: false,
      copyAdCheckData: { activeCopy: {}, allCopy: {} },
      budgetCheck: {},
      formData: {
        copyNum: 1,
        cloneSale: false,
      },
      optimizationGoalTypes,
      toAccount: [],
      saleInfo: null, // 商品信息
      siteInfo: null, // 站点信息
      promotionUrlLoading: false,
      siteList: [], // 站点列表
      nextEditDialogShow: false,
      treeParams: {},
    };
  },
  computed: {
    isTTShop() {
      return this.data.ad.objectiveEnum == 'PRODUCT_SALES';
    },

    checkData() {
      if (!this.data.ad) return {};
      if (this.level == 'campaign') {
        let baseCheck = {
          hasToAccountId: this.data.ad.platform == 'snapchat', // 跨账户
          hasToPixelId: this.data.ad.platform == 'snapchat', // 像素
          hasSaleRelationType: this.data.ad.platform == 'snapchat',
          hasConversionWindow: this.data.ad.platform == 'snapchat', // 转化时间窗
          hasPromotionUrl: this.data.ad.platform == 'snapchat' && this.formData.sale_relation_type == 'assign_sale',
          hasSaleId: this.data.ad.platform == 'snapchat' && this.formData.sale_relation_type == 'assign_sale',
          hasSiteId:
            this.data.ad.platform == 'snapchat' &&
            (this.formData.sale_relation_type == 'assign_sale' || this.formData.sale_relation_type == 'clone_sale'),
          hasCloneSale: this.data.ad.platform != 'snapchat',
          hasOptimization: this.data.ad.smartPerformanceCampaign,
          hasBidModel: this.data.ad.smartPerformanceCampaign,
          hasCampaignDailyBudget: this.data.ad.smartPerformanceCampaign, // 系列日预算--smart广告也用这个字段用于控制系列日预算的显示
        };
        const hasAdsetBidAmountType =
          this.formData.copeChildType == 'putIn'
            ? this.copyAdCheckData.activeCopy.hasAdsetBidAmount
            : this.copyAdCheckData.allCopy.hasAdsetBidAmount;
        // 其他平台和tt非smart广告的出价是不是显示是后端返回的；tt-smart广告是不是显示和优化目标和竞价策略联动
        const hasAdsetBidAmountValue = this.data.ad.smartPerformanceCampaign
          ? (this.formData.optimizationGoal === 'CONVERT' && this.formData.bidType === 'BID_TYPE_CUSTOM') ||
            (this.formData.optimizationGoal === 'VALUE' && this.formData.bidType === 'VO_MIN_ROAS')
          : hasAdsetBidAmountType;
        let bidCheck = {
          // 是否展示出价的输入框
          // 出价的输入框是不是有币种
          bidHasCurrency: this.formData.optimizationGoal === 'CONVERT' && this.formData.bidType === 'BID_TYPE_CUSTOM',
          // 平台
          platform: this.copyAdCheckData.platform,
          // 优化事件
          optimizationEvent: this.copyAdCheckData.allCopy.optimizationEvent,
          // 优化目标
          optimizationGoal: this.copyAdCheckData.allCopy.optimizationGoal,
          hasAdsetBidAmount: hasAdsetBidAmountValue,
        };
        // 投放中的广告组
        if (this.formData.copeChildType == 'putIn') {
          return {
            ...baseCheck,
            ...this.copyAdCheckData.activeCopy,
            ...bidCheck,
          };
        } else {
          // 全部广告组
          return {
            ...baseCheck,
            ...this.copyAdCheckData.allCopy,
            ...bidCheck,
          };
        }
      }
      if (this.level == 'adset') {
        return {
          hasCampaignDailyBudget: false, // 系列日预算
          hasCampaignLifetimeBudget: false, //系列总预算
          hasAdsetLifetimeBudget: this.data.ad.adGroupLifetimeBudget, // 广告组总预算
          hasAdsetDailyBudget: this.data.ad.adGroupDailyBudget, // 广告组日预算
          hasCampaignBidAmount: false, // 系列出价
          hasAdsetStartTime: true, //广告组开始时间
          hasAdsetEndTime: this.data.ad.schedules === 'startEnd', //开始时间
          bidHasCurrency: this.data.ad.bidHasCurrency, // 竞价是否是币种
          hasOptimization:
            this.data.ad.budgetModel != 'BUDGET_MODE_INFINITE' &&
            this.optimizationGoalTypes[this.data.ad.platform] &&
            !this.isTTShop, // 优化目标
          hasBidModel:
            this.data.ad.budgetModel != 'BUDGET_MODE_INFINITE' &&
            this.optimizationGoalTypes[this.data.ad.platform] &&
            !this.isTTShop,
          hasAdsetBidAmount: this.requiredAdsetBidAmount
            ? this.requiredAdsetBidAmount.needValue
            : this.data.ad.bidAmount, // 广告组出价
        };
      }
      return {};
    },
    maxLengthName() {
      if (this.data && this.data.ad) {
        return platformMaxLen[this.data.ad.platform] || -1;
      }
      return -1;
    },

    rules() {
      if (!this.data.ad) return {};
      const {
        hasCampaignDailyBudget,
        hasCampaignLifetimeBudget,
        hasAdsetLifetimeBudget,
        hasAdsetDailyBudget,
        hasCampaignBidAmount,
        hasAdsetBidAmount,
        hasAdsetStartTime,
        hasAdsetEndTime,
        hasToAccountId,
        hasPromotionUrl,
        hasSaleId,
        hasSiteId,
      } = this.checkData;
      let rules = {
        copyNum: {
          required: true,
          message: '请输入复制数量',
          trigger: ['blur'],
        },
        mStartTime: {
          required: hasAdsetStartTime,
          message: '请输入开始时间',
          trigger: ['blur'],
        },
      };
      if (hasCampaignDailyBudget) {
        rules.campaignDailyBudget = [
          {
            validator: (rule, value, callback) => {
              if (value && (value < this.budgetCheck.campaignBudgetMin || value > this.budgetCheck.campaignBudgetMax)) {
                return callback(true);
              }
              callback();
            },
            message: `预算在${this.budgetCheck.campaignBudgetMin}～${this.budgetCheck.campaignBudgetMax}区间`,
            trigger: ['blur'],
          },
        ];
      }
      if (hasCampaignLifetimeBudget) {
        rules.campaignLifetimeBudget = [
          {
            validator: (rule, value, callback) => {
              if (value && (value < this.budgetCheck.campaignBudgetMin || value > this.budgetCheck.campaignBudgetMax)) {
                return callback(true);
              }
              callback();
            },
            message: `预算在${this.budgetCheck.campaignBudgetMin}～${this.budgetCheck.campaignBudgetMax}区间`,
            trigger: ['blur'],
          },
        ];
      }
      if (hasAdsetLifetimeBudget) {
        rules.adsetLifetimeBudget = [
          {
            validator: (rule, value, callback) => {
              if (value && (value < this.budgetCheck.adsetBudgetMin || value > this.budgetCheck.adsetBudgetMax)) {
                return callback(true);
              }
              callback();
            },
            message: `预算在${this.budgetCheck.adsetBudgetMin}～${this.budgetCheck.adsetBudgetMax}区间`,
            trigger: ['blur'],
          },
        ];
      }

      if (hasAdsetDailyBudget) {
        rules.adsetDailyBudget = [
          {
            validator: (rule, value, callback) => {
              if (value && (value < this.budgetCheck.adsetBudgetMin || value > this.budgetCheck.adsetBudgetMax)) {
                return callback(true);
              }
              callback();
            },
            message: `预算在${this.budgetCheck.adsetBudgetMin}～${this.budgetCheck.adsetBudgetMax}区间`,
            trigger: ['blur'],
          },
        ];
      }
      if (hasCampaignBidAmount) {
        rules.campaignBidAmount = [
          {
            validator: (rule, value, callback) => {
              if (value && value < this.budgetCheck.bidMin && value > this.budgetCheck.bidMax) {
                return callback(true);
              }
              callback();
            },
            message: `预算在${this.budgetCheck.bidMin}～${this.budgetCheck.bidMax}区间`,
            trigger: ['blur'],
          },
        ];
      }
      if (hasAdsetBidAmount) {
        rules.adsetBidAmount = [
          {
            validator: (rule, value, callback) => {
              if (value && (value < this.budgetCheck.bidMin || value > this.budgetCheck.bidMax)) {
                return callback(true);
              }
              callback();
            },
            message: `出价在${this.budgetCheck.bidMin}～${this.budgetCheck.bidMax}区间`,
            trigger: ['blur', 'change'],
          },
          {
            required: this.requiredAdsetBidAmount,
            message: '请输入出价',
          },
        ];
      }
      if (hasAdsetEndTime) {
        rules.mEndTime = [
          {
            required: true,
            message: '请输入结束时间',
          },
          {
            validator: (rule, value, callback) => {
              if (value && moment(value).diff(moment(this.formData.mStartTime), 'days') < 1) {
                return callback(true);
              }
              callback();
            },
            message: '结束时间必须大于开始时间1天',
          },
        ];
      }
      if (hasToAccountId) {
        rules.toAccountId = [
          {
            required: true,
            message: '请输入目标账户',
          },
        ];
      }
      if (hasPromotionUrl) {
        rules.promotionUrl = [
          {
            required: true,
            message: '请输入推广连接',
          },
          {
            validator(rule, value, callback) {
              const reg =
                /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(\:[0-9]+)?)(\/[a-zA-Z0-9%_.~+-]+)*\/?(\?[a-zA-Z0-9%_.,~+-=&{}]*)?(#[a-zA-Z0-9_-{}]+)?$/;
              if (!reg.test(value)) {
                return callback(new Error('请输入合法域名'));
              }
              callback();
            },
            trigger: ['blur'],
          },
        ];
      }
      if (hasSaleId) {
        rules.toSaleId = [
          {
            required: true,
            message: '请输入商品id',
          },
        ];
      }

      if (hasSiteId) {
        rules.toSiteId = [
          {
            required: this.formData.sale_relation_type == 'assign_sale',
            message: '请输入站点',
          },
        ];
      }

      return {
        ...rules,
      };
    },
    optimizationList() {
      const platform = this.data.ad.platform || this.checkData.platform;
      const optimizationEvent = this.data.ad.smartPerformanceCampaign
        ? this.copyAdCheckData.allCopy.optimizationEvent
        : this.data.ad.optimizationEvent;
      const options = this.optimizationGoalTypes[platform];
      if (options && platform == 'tiktok' && optimizationEvent != 'SHOPPING') {
        return options.filter((item) => item.value == 'CONVERT');
      }
      return options || [];
    },
    bidModelList() {
      if (this.formData.optimizationGoal) {
        const option = this.optimizationList.find((item) => item.value == this.formData.optimizationGoal);
        if (option) {
          if (!this.canChangeValue) {
            return option.bidModelType.filter((item) => !item.needValue);
          }
          return option.bidModelType;
        }
      }
      return [];
    },
    requiredAdsetBidAmount() {
      const option = this.bidModelList.find((item) => item.value == this.formData.bidType);
      return option;
    },
    canChangeValue() {
      if (this.data.ad.platform == 'tiktok' && this.data.ad.budgetModel == 'BUDGET_MODE_INFINITE') {
        return false;
      }
      return true;
    },
    title() {
      switch (this.level) {
        case 'campaign':
          return '复制广告系列';
        case 'adset':
          return '复制广告组';
        case 'ad':
          return '复制广告';
        default:
          '复制';
          break;
      }
    },
  },
  methods: {
    multipleCopySuccess() {
      this.$emit('update:show', false);
    },
    handleNextEdit() {
      const {
        hasCampaignDailyBudget,
        hasCampaignLifetimeBudget,
        hasAdsetLifetimeBudget,
        hasAdsetDailyBudget,
        hasCampaignBidAmount,
        hasAdsetBidAmount,
        hasAdsetEndTime,
        hasOptimization,
        hasBidModel,
        hasCloneSale,
      } = this.checkData;
      let params = {
        level: this.level,
        platform: this.data.ad.platform,
        fromAccountId: this.data.ad.adAccountId,
        toAccountId: this.formData.toAccountId || this.data.ad.adAccountId,
        copeChildType: this.formData.copeChildType,
        fromCampaign: this.data.ad.campaignId,
        toCampaign: this.data.ad.campaignId,
        fromAdGroup: this.data.ad.adGroupId,
        toAdGroup: this.data.ad.adGroupId,
        fromAd: this.data.ad.adId,
        campaignDailyBudget: hasCampaignDailyBudget ? this.formData.campaignDailyBudget : null,
        adsetDailyBudget: hasAdsetDailyBudget ? this.formData.adsetDailyBudget : null,
        campaignLifetimeBudget: hasCampaignLifetimeBudget ? this.formData.campaignLifetimeBudget : null,
        adsetLifetimeBudget: hasAdsetLifetimeBudget ? this.formData.adsetLifetimeBudget : null,
        campaignBidAmount: hasCampaignBidAmount ? this.formData.campaignBidAmount : null,
        copyNum: this.formData.copyNum,
        name: this.formData.name,
        hasEdit:
          this.level == 'ad'
            ? this.data.ad.adName != this.formData.name
            : this.data.ad.campaignName != this.formData.name,
        mStartTime: this.level == 'ad' ? null : this.formData.mStartTime,
        mEndTime: hasAdsetEndTime ? this.formData.mEndTime : null,
        optimizationGoal: hasOptimization ? this.formData.optimizationGoal : null, //优化目标
        bidType: hasBidModel ? this.formData.bidType : null, // 竞价策略
        adsetBidAmount: hasAdsetBidAmount ? this.formData.adsetBidAmount : null,
        adsetActive: this.level == 'adset' ? this.formData.adsetActive : null,
        adActive: this.level == 'ad' ? this.formData.adActive : null,
        toSiteId: this.siteInfo ? this.siteInfo.id : null,
        toSaleId: this.saleInfo ? this.saleInfo.id : null,
        toPixelId: this.formData.toPixelId,
        campaignActive: this.formData.campaignActive,
        cloneSale: hasCloneSale ? this.formData.cloneSale : null,
      };
      this.treeParams = {
        ...params,
        conversionWindow: this.formData.conversionWindow,
        replaceOption: {
          promotionUrl: this.formData.promotionUrl,
          targetPixelId: this.formData.toPixelId,
          sale: {
            ...this.saleInfo,
          },
          site: {
            ...this.siteInfo,
          },
          sale_relation_type: this.formData.sale_relation_type,
        },
      };
      console.log(this.treeParams);
      this.nextEditDialogShow = true;
    },
    updateNextEditDialogShow() {
      this.nextEditDialogShow = false;
    },
    close() {
      this.$emit('update:show', false);
    },
    // 复制前检查
    copyAdCheck() {
      if (this.level == 'campaign') {
        this.checkCampaign();
      } else {
        this.checkAdset();
      }
    },
    // 获取检测接口
    checkCampaign() {
      let params = {
        level: this.level,
        fromAccountId: this.data.ad.adAccountId,
        platform: this.data.ad.platform,
        fromCampaign: this.data.ad.campaignId,
        currency: this.data.statistics.currency,
        objectiveEnum: this.data.ad.objectiveEnum,
        isSmartPlusCampaign: this.data.ad.smartPerformanceCampaign,
      };
      this.loading = true;
      copyAdCheck(params)
        .finally(() => {
          this.loading = false;
        })
        .then((res) => {
          if (!res.data.isCanCopyActive) {
            this.$set(this.formData, 'copeChildType', 'total');
          } else {
            this.$set(this.formData, 'copeChildType', 'putIn');
          }
          this.budgetCheck = {
            ...res.data.budgetCheck,
          };
          this.copyAdCheckData = {
            ...res.data,
          };
          this.formData.optimizationGoal = this.copyAdCheckData.allCopy.optimizationGoal;
          this.formData.bidType = this.copyAdCheckData.allCopy.bidType;
          this.formData.adsetBidAmount = this.copyAdCheckData.allCopy.bidAmount;
        })
        .catch((err) => {
          this.close();
        });
    },
    checkAdset() {
      let params = {
        platform: this.data.ad.platform,
        currency: this.data.currency,
        objectiveEnum: this.data.ad.objectiveEnum,
      };
      this.loading = true;
      queryConfigDataCheck(params)
        .then((res) => {
          this.budgetCheck = {
            ...res.data,
          };
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 修改推广连接 获取商品信息和站点信息
    onChangePromotionUrl() {
      if (!this.formData.promotionUrl || !this.formData.promotionUrl.trim()) return;
      let params = {
        url: this.formData.promotionUrl.trim(),
      };
      const reg =
        /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(\:[0-9]+)?)(\/[a-zA-Z0-9%_.~+-]+)*\/?(\?[a-zA-Z0-9%_.,~+-=&{}]*)?(#[a-zA-Z0-9_-{}]+)?$/;
      if (!reg.test(this.formData.promotionUrl)) {
        return;
      }
      promotionInfo(params)
        .then((res) => {
          this.promotionUrlLoading = true;
          this.$set(this.formData, 'toSaleId', res.data.sale.id);
          this.$set(this.formData, 'toSiteId', res.data.site.id);

          this.saleInfo = res.data.sale;
          this.siteInfo = res.data.site;
          this.siteList = [{ ...res.data.site }];
        })
        .finally(() => {
          this.promotionUrlLoading = false;
        });
    },
    onChangeSaleRelationType() {
      this.siteInfo = null;
      this.saleInfo = null;
      this.$set(this.formData, 'promotionUrl', '');
    },
    sure() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.loading = true;
          const {
            hasCampaignDailyBudget,
            hasCampaignLifetimeBudget,
            hasAdsetLifetimeBudget,
            hasAdsetDailyBudget,
            hasCampaignBidAmount,
            hasAdsetBidAmount,
            hasAdsetEndTime,
            hasOptimization,
            hasBidModel,
            hasCloneSale,
          } = this.checkData;
          let params = {
            level: this.level,
            platform: this.data.ad.platform,
            fromAccountId: this.data.ad.adAccountId,
            toAccountId: this.formData.toAccountId || this.data.ad.adAccountId,
            copeChildType: this.formData.copeChildType,
            fromCampaign: this.data.ad.campaignId,
            toCampaign: this.data.ad.campaignId,
            fromAdGroup: this.data.ad.adGroupId,
            toAdGroup: this.data.ad.adGroupId,
            fromAd: this.data.ad.adId,
            campaignDailyBudget: hasCampaignDailyBudget ? this.formData.campaignDailyBudget : null,
            adsetDailyBudget: hasAdsetDailyBudget ? this.formData.adsetDailyBudget : null,
            campaignLifetimeBudget: hasCampaignLifetimeBudget ? this.formData.campaignLifetimeBudget : null,
            adsetLifetimeBudget: hasAdsetLifetimeBudget ? this.formData.adsetLifetimeBudget : null,
            campaignBidAmount: hasCampaignBidAmount ? this.formData.campaignBidAmount : null,
            copyNum: this.formData.copyNum,
            name: this.formData.name,
            hasEdit:
              this.level == 'ad'
                ? this.data.ad.adName != this.formData.name
                : this.data.ad.campaignName != this.formData.name,
            mStartTime: this.formData.mStartTime,
            mEndTime: hasAdsetEndTime ? this.formData.mEndTime : null,
            optimizationGoal: hasOptimization ? this.formData.optimizationGoal : null, //优化目标
            bidType: hasBidModel ? this.formData.bidType : null, // 竞价策略
            adsetBidAmount: hasAdsetBidAmount ? this.formData.adsetBidAmount : null,
            adsetActive: this.level == 'adset' ? this.formData.adsetActive : null,
            adActive: this.level == 'ad' ? this.formData.adActive : null,

            toSiteId: this.siteInfo ? this.siteInfo.id : null,
            toSaleId: this.saleInfo ? this.saleInfo.id : null,
            toPixelId: this.formData.toPixelId,
            campaignActive: this.formData.campaignActive,
            cloneSale: hasCloneSale ? this.formData.cloneSale : null,
          };
          if (this.level == 'campaign' && this.data.ad.platform == 'snapchat') {
            params = {
              ...params,
              conversionWindow: this.formData.conversionWindow,
              replaceOption: {
                promotionUrl: this.formData.promotionUrl,
                targetPixelId: this.formData.toPixelId,
                sale: {
                  ...this.saleInfo,
                },
                site: {
                  ...this.siteInfo,
                },
                sale_relation_type: this.formData.sale_relation_type,
              },
            };
          }
          if (this.level === 'campaign') {
            params.isSmartPlusCampaign = this.data.ad.smartPerformanceCampaign;
          }
          copyAdAsync(params)
            .finally(() => {
              this.loading = false;
            })
            .then((res) => {
              if (res.code == 0) {
                this.$message.success('复制成功，请稍后查看结果');
                this.close();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    // 修改优化目标
    changeOptimizationGoal() {
      this.$set(this.formData, 'bidType', this.bidModelList.length ? this.bidModelList[0].value : null);
    },
  },
  watch: {
    show(bool) {
      if (bool && this.data) {
        this.copyAdCheck();
        this.siteList = [];
        this.siteInfo = null;
        this.saleInfo = null;
        const optimizationGoal = this.data.ad.optimizationGoal || this.copyAdCheckData.allCopy.optimizationGoal;
        const bidType = this.data.ad.bidType || this.copyAdCheckData.allCopy.bidModel;
        this.$nextTick(() => {
          const data = moment().format('YYYY-MM-DD HH:mm:ss');
          this.formData = {
            copyNum: 1,
            name: this.level == 'ad' ? this.data.ad.adName : this.data.ad.campaignName,
            toAccountId: this.data.ad.adAccountId,
            campaignDailyBudget: this.data.ad.campaignDailyBudget ? `${this.data.ad.campaignDailyBudget}` : null,
            adsetDailyBudget: this.data.ad.adGroupDailyBudget ? `${this.data.ad.adGroupDailyBudget}` : null,
            campaignLifetimeBudget: this.data.ad.campaignLifetimeBudget
              ? `${this.data.ad.campaignLifetimeBudget}`
              : null,
            adsetLifetimeBudget: this.data.ad.adGroupLifetimeBudget ? `${this.data.ad.adGroupLifetimeBudget}` : null,
            campaignBidAmount: this.data.ad.bidAmount ? `${this.data.ad.bidAmount}` : null,
            adsetBidAmount: this.data.ad.bidAmount ? `${this.data.ad.bidAmount}` : null,

            mStartTime: data,
            mEndTime: moment().add(30, 'days').format('YYYY-MM-DD 23:59:59'),
            optimizationGoal: optimizationGoal,
            bidType: bidType,
            sale_relation_type: 'default_sale',
            campaignActive: this.level == 'campaign' ? true : undefined,
            adsetActive: this.level == 'adset' ? true : null,
            adActive: this.level == 'ad' ? true : null,
            conversionWindow: '',
            cloneSale: false,
          };
        });
      }
    },
    // 复制数量改变的时候
    'formData.copyNum'(val, oldValue) {
      const regex = /^([1-9]|10)$/;
      const reg = !regex.test(val);
      if (val && val != 1) {
        this.$set(this.formData, 'name', this.level == 'ad' ? this.data.ad.adName : this.data.ad.campaignName);
      }
    },
  },
};
</script>
<style lang="scss">
.copy .el-input-number {
  .el-input__inner {
    text-align: left;
  }
}
</style>
